import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Footer = () => {

    const toTop = () =>{
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
        });
    };

    const [contactDetails, setContactDetails] = useState([]);
    const [Pending, setPending] = useState(true);
    const [error, setError] = useState(null);

    const [branches, setBranches] = useState([]);
    const [pages, setPages] = useState([]);

    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/contact-details`)
            .then(res => {
                if(!res.ok) {
                    throw Error('Could not fetch for that resource...');
                }
                return res.json();
            })
            .then((data) => {
                setContactDetails(data.data);
                setPending(false);
                setError(null);
            })
            .catch(err => {
                setError(err.message);
                setPending(false);
            })


        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/branches`)
            .then(res => {
                if(!res.ok) {
                    throw Error('Could not fetch for that resource...');
                }
                return res.json();
            })
            .then((data) => {
                setBranches(data.data);
                // setIsPending(false);
                // setError(null);
                
            })
            .catch(err => {
                // setError(err.message);
                // setIsPending(false);
            })


        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/pages`)
            .then(res => {
                if(!res.ok) {
                    throw Error('Could not fetch for that resource...');
                }
                return res.json();
            })
            .then((data) => {
                setPages(data.data);
                // setIsPending(false);
                // setError(null);
                
            })
            .catch(err => {
                // setError(err.message);
                // setIsPending(false);
            })
        
    }, []);

    return (
        <div className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 p-0">
                        <img src={require('../assets/images/common/footer_banner.png')} alt="logo" className="w-100 footer-banner" />
                    </div>
                </div>
            </div>
            
            <div className="min-wrapper">
                <div className="container-fluid">
                    <div className="row top">
                        <div className="column1">
                            <img src={require('../assets/images/common/logo.png')} alt="logo" className="footer-logo" />
                        </div>

                        { branches.map((branch, index) => (

                            index === 0 ? (
                                <div className="column2" key={ index }>
                                    <h3 className="name">Famco</h3>
                                    <p className="address">{ branch.address }</p>
                                    <p className="address">{ branch.city }</p>
                                    <p className="address"><a href={ `tel:${ branch.phone }` }>{ branch.phone }</a></p>
                                    <p className="address"><a href={ `mailto:${ branch.email }` }>{ branch.email }</a></p>
                                </div>
                            ) : null
                        ))}
                        <div className="column3">
                            <h6 className="title">Quick Links</h6>
                            <ul>
                                <li><Link to="/">Home</Link></li>

                                { pages.map((page, index) => (
                                    index === 0 && page.name === 'About Us' && page.status === 1 ? (
                                        <li key={ index }><Link to="/about-us">About Us</Link></li>
                                    ) : null
                                ))}

                                { pages.map((page, index) => (
                                    index === 1 && page.name === 'Management' && page.status === 1 ? (
                                        <li key={ index }><Link to="/management">Management</Link></li>
                                    ) : null
                                ))}

                                { pages.map((page, index) => (
                                    index === 2 && page.name === 'Products' && page.status === 1 ? (
                                        <li key={ index }><Link to="/products">Products</Link></li>
                                    ) : null
                                ))}

                            </ul>
                        </div>
                        <div className="column4">
                            <h6 className="title">Quick Links</h6>
                            <ul>
                                { pages.map((page, index) => (
                                    index === 3 && page.name === 'Award & Certifications' && page.status === 1 ? (
                                        <li key={ index }><Link to="/awards-certifications">Award & Certifications</Link></li>
                                    ) : null
                                ))}

                        { pages.map((page, index) => (
                            index === 4 && page.name === 'Testimonials' && page.status === 1 ? (
                                <li key={ index }><Link to="/testimonials">Testimonials</Link></li>
                            ) : null
                        ))}

                        { pages.map((page, index) => (
                            index === 5 && page.name === 'Blogs' && page.status === 1 ? (
                                <li key={ index }><Link to="/blogs">Blogs</Link></li>
                            ) : null
                        ))}

                        { pages.map((page, index) => (
                            index === 6 && page.name === 'Contact Us' && page.status === 1 ? (
                                <li key={ index }><Link to="/contact-us">Contact Us</Link></li>
                            ) : null
                        ))}
                            </ul>
                        </div>
                        <div className="column5">
                            <h6 className="title">More</h6>

                                { error && <div className="loading text-center">{ error }</div> }

                                { Pending && <div className="loading text-center">Loading...</div> }

                                { contactDetails !== null &&
                                    <p className="description">{ contactDetails.footer_paragraph }</p>
                                }

                                { contactDetails !== null &&
                                    <div className="social">
                                        <a href={ contactDetails.fb_link } target="_blank" rel="noreferrer">
                                            <img src={require('../assets/images/common/fb_icon.png')} alt="icon" className="icon" />
                                        </a>
                                        <a href={ contactDetails.linked_in_link } target="_blank" rel="noreferrer">
                                            <img src={require('../assets/images/common/linked_icon.png')} alt="icon" className="icon" />
                                        </a>
                                        <a href={ contactDetails.twitter_link } target="_blank" rel="noreferrer">
                                            <img src={require('../assets/images/common/twitter_icon.png')} alt="icon" className="icon" />
                                        </a>
                                    </div>
                                }
                        </div>
                    </div>

                    <div className="row bottom">
                        <div className="col-12 text-center">
                            <p className="copyright">Copyright © 2022 - Famco - All Rights Reserved. Developed by <a href="https://www.freelancer.com/u/zajjithvedha" className="developer" target="_blank" rel="noreferrer">Zajjith Vedha</a></p>

                            <i className="bi bi-chevron-up to-top" onClick={toTop}></i>
                        </div>
                    </div>

                    <img src={require('../assets/images/common/footer_image.png')} alt="logo" className="footer-image" />
                </div>
            </div>
        </div>
    );
}
 
export default Footer;