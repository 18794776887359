import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const AboutUs = () => {

    const playVideo = () => {
        document.querySelector('.pos-abs').style.display = 'none'
    }

    const [about, setAbout] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        // document.title = 'Famco | About Us';

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/about-us`)
            .then(res => {
                if(!res.ok) {
                    throw Error('Could not fetch for that resource...');
                }
                return res.json();
            })
            .then((data) => {
                setAbout(data.data);
                setIsPending(false);
                setError(null);
            })
            .catch(err => {
                setError(err.message);
                setIsPending(false);
            })
        
    }, []);

    return (

        <div className="about-us">

            <Helmet>
                <title>FAMCO Since 1984 | About Us</title>
		<meta name="Keywords" content="FAMCO, FAMCO Mysore, Bone Meal, Neem Cake, Mysore Meal, Organic Fertilizer, Kerala Bone Meal, Pure Bone Meal, Neem Cake For Organic Gardening, Organic Fertilizer, Fertilizer"></meta>
                <meta name="Description" content="Find The Pure Bone Meal, Neem Cake, Mysore Meal and organic Fertilizers at The Lowest Price."></meta>
            </Helmet>

            { error && <div className="loading text-center">{ error }</div>}

            { isPending && <div className="loading text-center">Loading...</div>}

            { about.length > 0 &&

                <section className="min-wrapper">
                    { about.map(ab => (
                        <div className="container-fluid" key={ ab.id } >

                            <div className="row main-about align-items-center">
                                <h5 className="title">About Us</h5>

                                <div className="col-12 col-md-7 mb-3 mb-md-0">
                                    <p className="description">{ ab.about_us_description }</p>
                                </div>
                                <div className="col-12 col-md-5 text-center">
                                    <div className="background">
                                        <img src={ ab.about_us_image } alt="About" className="about-image" />
                                    </div>
                                </div>
                            </div>

                            <div className="row brands align-items-center">
                                <h5 className="title">Our Associate Brands</h5>

                                <div className="col-12 col-md-5 mb-2 mb-md-0 text-center text-md-left">
                                    { ab.brands_image.map((brand_image, index) => (
                                        <img src={ brand_image } key={ index } alt="Brand" className="brand-image" />
                                    )) }

                                </div>

                                <div className="col-12 col-md-7">
                                    <p className="description">{ ab.brands_description }</p>
                                </div>
                            </div>

                            <div className="row supply align-items-center">
                                <h5 className="title">Our Supply</h5>

                                <div className="col-12 text-center">
                                    <img src={ ab.supply_map_image } alt="Map" className="map" />

                                    <p className="description">{ ab.supply_description }</p>
                                </div>
                            </div>

                            { ab.company_video_show === 'yes' &&

                                <div className="row company">
                                    <h5 className="title">Company Profile</h5>

                                    <div className="col-12 video-div">
                                        <div className="pos-abs" onClick={ playVideo }>
                                            <div className="elements">
                                                <img src={ ab.video_image } alt="Video" className="video-image" />
                                                <div className="blur"></div>
                                                <img src={require('../assets/images/about_us/play.png')} alt="Play Button" className="play" />
                                            </div>
                                        </div>
                                        
                                        <iframe src={ ab.video_link } title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    </div>
                                </div>
                            }

                            <div className="row management">
                                <h5 className="title">Founder & Management Awards</h5>

                                <div className="col-12">

                                    {/* <div className="row top-row justify-content-center">
                                        <div className="col-12 col-md-4 text-center">
                                            <img src={ ab.founder['image'] } alt="Founder" className="founder-image" />
                                            <p className="name">{ ab.founder['name'] }</p>
                                            <p className="designation">{ ab.founder['designation'] }</p>
                                        </div>
                                    </div> */}

                                    <div className="row bottom-row">
                                        {/* <div className="col-12 top-column">

                                            <div className="row align-items-center">
                                                <div className="col-12 col-md-4 mb-2 mb-md-0 text-center">
                                                    <img src={ ab.manager['image'] } alt="Manager" className="manager-image" />

                                                    <p className="name">{ ab.manager['name'] }</p>
                                                    <p className="designation">{ ab.manager['designation'] }</p>
                                                </div>

                                                <div className="col-12 col-md-8">
                                                    <p className="description">{ ab.manager['description'] }</p>
                                                </div>
                                            </div>

                                            { ab.members.map(member => (
                                                <div className="row member-row align-items-center" key={ member.id }>
                                                   <div className="col-12 col-md-4 mb-2 mb-md-0 text-center">
                                                       <img src={ member.image } alt="Member" className="member-image" />
   
                                                       <p className="name">{ member.name }</p>
                                                       <p className="designation">{ member.designation }</p>
                                                   </div>
   
                                                   <div className="col-12 col-md-8">
                                                       <p className="description">{ member.description }</p>
                                                   </div>
                                               </div>
                                            ))}

                                        </div> */}

                                        <div className="col-12 bottom-column text-center">
                                            <img src={ ab.award_image } alt="Award Receiving" className="award-image" />

                                            <p className="description">{ ab.award_description }</p>
                                        </div>
                                    </div>
                                    

                                </div>
                            </div>

                            {/* <div className="row members">
                                <h5 className="title">Management</h5>

                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="description text-center">{ ab.management_description }</p>
                                        </div>
                                    </div>

                                    <div className="row top-row justify-content-center">
                                        { ab.members.map((member, index) => (

                                            index === 0 ? (
                                                <div className="col-12 col-md-4 text-center" key={ member.id }>
                                                    <img src={ member.image } alt="Management Member" className="management-image" />
                                                    <p className="name">{ member.name }</p>
                                                    <p className="designation">{ member.designation }</p>
                                                </div>
                                            
                                            ) : null)

                                        )}
                                    </div>

                                    <div className="row bottom-row">
                                        { ab.members.map((member, index) => (

                                            index !== 0 ? (

                                                <div className="column" key={ member.id }>
                                                    <img src={ member.image } alt="Management Member" className="management-image" />
                                                    <p className="name">{ member.name }</p>
                                                    <p className="designation">{ member.designation }</p>
                                                </div>

                                            ) : null)

                                        )}
                                    </div>
                                </div>

                                
                        
                            </div> */}

                        </div>
                    )) }
                </section>

            }

        </div>
    );
    
}

export default AboutUs;
