import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const Management = () => {

    const [isDesktop, setDesktop] = useState(window.innerWidth > 650);

    const updateMedia = () => {
        setDesktop(window.innerWidth > 650);
    };

    const [members, setMembers] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        // document.title = 'Famco | Management';

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/members`)
            .then(res => {
                if(!res.ok) {
                    throw Error('Could not fetch for that resource...');
                }
                return res.json();
            })
            .then((data) => {
                setMembers(data.data);
                setIsPending(false);
                setError(null);
            })
            .catch(err => {
                setError(err.message);
                setIsPending(false);
            })

        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
        
    }, []);


    return (

        <div className="members">

            <Helmet>
                <title>FAMCO Since 1984 | Management</title>
                <meta name="Description" content="A M Abdul Rahim's Vision For the FAMCO Comapnay makes it one of the top Bone Meal, Neem Cake, Mysore Meal and Organic Fertiliser Suppliers in South India."></meta>
		<meta name="Keywords" content="FAMCO, FAMCO Mysore, Bone Meal, Neem Cake, Mysore Meal, Organic Fertilizer, Kerala Bone Meal, Pure Bone Meal, Neem Cake For Organic Gardening, Organic Fertilizer, Fertilizer"></meta>
            </Helmet>

            <div className="min-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="title">Founder & Management</h1>
                        </div>
                    </div>

                    { error && <div className="loading text-center">{ error }</div>}

                    { isPending && <div className="loading text-center">Loading...</div>}

                    { members.length > 0 &&
                    
                        <div className="all-members">

                            { members.map((member, index) => (

                                index % 2 === 0 ? (
                                    <div className="row member-row align-items-center" key={ member.id }>
                                        <div className="col-12 col-md-4 mb-2 mb-md-0 text-center">
                                            <img src={ member.image } alt="Member" className="member-image" />

                                            <p className="name">{ member.name }</p>
                                            <p className="designation">{ member.designation }</p>
                                        </div>

                                        <div className="col-12 col-md-8">
                                            <p className="description">{ member.description }</p>
                                        </div>
                                    </div>
                                ) :
                                    (!isDesktop ? (
                                        <div className="row member-row align-items-center" key={ member.id }>
                                            <div className="col-12 col-md-4 mb-2 mb-md-0 text-center">
                                                <img src={ member.image } alt="Member" className="member-image" />

                                                <p className="name">{ member.name }</p>
                                                <p className="designation">{ member.designation }</p>
                                            </div>

                                            <div className="col-12 col-md-8">
                                                <p className="description">{ member.description }</p>
                                            </div>
                                        </div>
                                        ) : (
                                            <div className="row member-row align-items-center" key={ member.id }>
                                    
                                                <div className="col-12 col-md-8">
                                                    <p className="description">{ member.description }</p>
                                                </div>

                                                <div className="col-12 col-md-4 mb-2 mb-md-0 text-center">
                                                    <img src={ member.image } alt="Member" className="member-image" />

                                                    <p className="name">{ member.name }</p>
                                                    <p className="designation">{ member.designation }</p>
                                                </div>
                                            </div>
                                            )
                                        ) 
                                )
                            )}

                        </div>

                    }
                </div>
            </div>
        </div>

    );
}

export default Management;
