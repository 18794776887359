import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const Products = () => {

    const [products, setProducts] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    // const [featuredImage, setFeaturedImage] = useState(null);

    const imageChange = (no, e) => {

        const img = e.target.src;

        const feature = document.getElementById("featured-image-" + no);

        feature.src = img;

        // setTimeout(changeImage(no), 5000);
    }

    // const changeImage = (no) => {
    //     const feature = document.getElementById("featured-image-" + no);
    //     feature.src = featuredImage;
    // }

    useEffect(() => {

        // document.title = 'Famco | Products';

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/products`)
            .then(res => {
                if(!res.ok) {
                    throw Error('Could not fetch for that resource...');
                }
                return res.json();
            })
            .then((data) => {
                setProducts(data.data);
                setIsPending(false);
                setError(null);
            })
            .catch(err => {
                setError(err.message);
                setIsPending(false);
            })
        
    }, []);

    return (

        <section className="products">

            <Helmet>
                <title>Famco Since 1984 | Products</title>
                <meta name="Description" content="FAMCO is one of the pioneers in Bone Meal, Neem Cake, Mysore Meal Organic Fertilisers production. FAMCO is Kerala's most preferred brand Call 9448739609 for more details"></meta>
		<meta name="Keywords" content="FAMCO, FAMCO Mysore, Bone Meal, Neem Cake, Mysore Meal, Organic Fertilizer, Kerala Bone Meal, Pure Bone Meal, Neem Cake For Organic Gardening, Organic Fertilizer, Fertilizer"></meta>
            </Helmet>

            <div className="min-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="title">The best <span>USAGE</span> of our products</h1>
                            <p className="description">FAMCO currently offers its top product, Bone Meal, and plans to introduce other products such as Mysore Meal, Swarna meal, Neem Cake, City Compost & Vermicompost</p>
                        </div>
                    </div>

                    { error && <div className="loading text-center">{ error }</div>}

                    { isPending && <div className="loading text-center">Loading...</div>}

                    { products.length > 0 &&

                        <div className="row all-products">
                            { products.map(product => (
                                <div className="single-product" key={ product.id }>
                                    <div className="row top-row justify-content-between">
                                        <div className="left">
                                            <div className="white-box">
                                                <div className="main-image">
                                                    <img src={ product.image } className="featured-image" id={`featured-image-${ product.id }`} alt="Product"/>
                                                </div>

                                                <div className="other-images">
                                                    { product.other_images.map((other_image, index) => (
                                                        <img src={ other_image } className="other-image" alt="Other" key={ index } onClick = { (e) => imageChange(product.id, e) }/>
                                                    )) }
                                                </div>

                                            </div>
                                        </div>
                                        <div className="right">
                                            <h6 className="product-title">{ product.name }</h6>
                                            <p className="product-description">{ product.description }</p>
                                        </div>
                                    </div>

                                    <div className="bottom-row">
                                        <div className="sub-images">
                                            <div className="row justify-content-center">

                                                { product.sub_images.map((sub_image, index) => (
                                                    <div className="image-column" key={ index } >
                                                        <div className="image-name-div">
                                                            <img src={ sub_image.image } alt="Sub Product"/>
                                                            <p className="image-name">{ sub_image.name }</p>
                                                        </div>

                                                        <p className="image-description">{ sub_image.description }</p>
                                                        
                                                        <div className="bar"></div>
                                                    </div>
                                                )) }

                                            </div>
                                        </div>

                                        <div className="background-box"></div>
                                    </div>
                                    
                                </div>
                            )) }
                        </div>

                    }
                </div>
            </div>
        </section>
        
    );
}

export default Products;
