import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';

const SingleBlog = () => {

    const { id } = useParams();
    const [blog, setBlog] = useState([]);

    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/blogs/` + id)
            .then(res => {
                return res.json();
            })
            .then((data) => {
                setBlog(data.data);
            })

    }, [id]);

    return (

        <div className="single-blog">

            <Helmet>
                <title>Single Blog</title>
                <meta name="Single Blog" content="Single Blog"></meta>
            </Helmet>

            <div className="wrapper">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-9 px-0 px-md-2">

                            <h1 className="title">{ blog.title }</h1>

                            <img src={ blog.image } className="w-100 image" alt="Article" />

                            <div className="description" dangerouslySetInnerHTML={{ __html: blog.description }} />
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default SingleBlog;