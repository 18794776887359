import { NavLink, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Navbar = () => {

    const [pages, setPages] = useState([]);
    // const [isPending, setIsPending] = useState(true);
    // const [error, setError] = useState(null);


    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/pages`)
            .then(res => {
                if(!res.ok) {
                    throw Error('Could not fetch for that resource...');
                }
                return res.json();
            })
            .then((data) => {
                setPages(data.data);
                // setIsPending(false);
                // setError(null);
                
            })
            .catch(err => {
                // setError(err.message);
                // setIsPending(false);
            })
        
    }, []);


    


    return ( 
        <nav className="navbar navbar-expand-lg shadow-sm">
            <div className="container-fluid">
                <NavLink className="navbar-brand" exact to="/">
                    <img src={require('../assets/images/common/logo.png')} alt="logo" className="logo" />
                </NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbar">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item"><NavLink className="nav-link" activeClassName="active" exact to="/">Home</NavLink></li>

                        
                        <li className="nav-item dropdown">
                            <button className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">About</button>
                            <ul className="dropdown-menu">

                                { pages.map((page, index) => (
                                    index === 0 && page.name === 'About Us' && page.status === 1 ? (
                                        <li key={ index }><Link className="nav-link" to="/about-us">About Us</Link></li>
                                    ) : null
                                ))}

                                { pages.map((page, index) => (
                                    index === 1 && page.name === 'Management' && page.status === 1 ? (
                                        <li key={ index }><Link className="nav-link" to="/management">Management</Link></li>
                                    ) : null
                                ))}

                            </ul>
                        </li>
                        

                        { pages.map((page, index) => (
                            index === 2 && page.name === 'Products' && page.status === 1 ? (
                                <li className="nav-item" key={ index }><NavLink className="nav-link" activeClassName="active" to="/products">Products</NavLink></li>
                            ) : null
                        ))}

                        { pages.map((page, index) => (
                            index === 3 && page.name === 'Award & Certifications' && page.status === 1 ? (
                                <li className="nav-item" key={ index }><NavLink className="nav-link" activeClassName="active" to="/awards-certifications">Award & Certifications</NavLink></li>
                            ) : null
                        ))}

                        { pages.map((page, index) => (
                            index === 4 && page.name === 'Testimonials' && page.status === 1 ? (
                                <li className="nav-item" key={ index }><NavLink className="nav-link" activeClassName="active" to="/testimonials">Testimonials</NavLink></li>
                            ) : null
                        ))}

                        { pages.map((page, index) => (
                            index === 5 && page.name === 'Blogs' && page.status === 1 ? (
                                <li className="nav-item" key={ index }><NavLink className="nav-link" activeClassName="active" to="/blogs">Blogs</NavLink></li>
                            ) : null
                        ))}

                        { pages.map((page, index) => (
                            index === 6 && page.name === 'Contact Us' && page.status === 1 ? (
                                <li className="nav-item" key={ index }><NavLink className="nav-link" activeClassName="active" to="/contact-us">Contact Us</NavLink></li>
                            ) : null
                        ))}
                        
                        <li className="nav-item" id="google_translate_element"></li>
                    </ul>
                </div>
            </div>
        </nav>

    );
}
 
export default Navbar;