import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination,Navigation } from 'swiper/core';
import { Helmet } from 'react-helmet';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


const Testimonials = () => {

    const [testimonials, setTestimonials] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        // document.title = 'Famco | Testimonials';

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/testimonials`)
            .then(res => {
                if(!res.ok) {
                    throw Error('Could not fetch for that resource...');
                }
                return res.json();
            })
            .then((data) => {
                setTestimonials(data.data);
                setIsPending(false);
                setError(null);
            })
            .catch(err => {
                setError(err.message);
                setIsPending(false);
            })
        
    }, []);


    return (

        <div className="testimonials">

            <Helmet>
                <title>Testimonials</title>
                <meta name="Testimonials" content="Testimonials"></meta>
            </Helmet>

            <div className="wrapper">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <h1 className="title">See, what our <span>CUSTOMERS</span> say</h1>
                        </div>
                    </div>

                    { error && <div className="loading text-center">{ error }</div>}

                    { isPending && <div className="loading text-center">Loading...</div>}

                    { testimonials.length > 0 &&
                    
                        <div className="row reviews">
                            
                            <Swiper
                                modules={[Navigation, Pagination]}
                                loop={true}
                                spaceBetween={50}
                                slidesPerView={3}
                                pagination={{ clickable: true }}
                                breakpoints={{
                                    0: {
                                      slidesPerView: 1,
                                    },
                                    429: {
                                        slidesPerView: 2,
                                        spaceBetween: 40
                                    },
                                    1025: {
                                      slidesPerView: 3,
                                    },
                                }}
                            >
                                { testimonials.map(testimonial => (

                                    <SwiperSlide className="single-review" key={ testimonial.id }>

                                        <img src={ testimonial.image } alt="Testimonial Author" className="review-image" />

                                        <img src={require('../assets/images/testimonials/shape.png')} alt="Shape" className="shape" />
                                        <img src={require('../assets/images/testimonials/quote.png')} alt="Shape" className="quote" />

                                        <p className="description">{ testimonial.comment }</p>

                                        <h5 className="name">{ testimonial.name }</h5>
                                        <p className="designation">{ testimonial.designation }</p>

                                        <div className="bars">
                                            <div className="bar bar1"></div>
                                            <div className="bar bar2"></div>
                                        </div>

                                    </SwiperSlide>

                                )) }

                            </Swiper>

                        </div>
                    }

                </div>
            </div>
        </div>

    );
}

export default Testimonials;