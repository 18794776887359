import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination,Navigation } from 'swiper/core';
import { Helmet } from 'react-helmet';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Home = () => {

    // document.title = 'Famco | Home';

    const [isDesktop, setDesktop] = useState(window.innerWidth > 650);

    const updateMedia = () => {
        setDesktop(window.innerWidth > 650);
    };

    const [home, setHome] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);


    const [pages, setPages] = useState([]);

    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/home`)
            .then(res => {
                if(!res.ok) {
                    throw Error('Could not fetch for that resource...');
                }
                return res.json();
            })
            .then((data) => {
                setHome(data.data);
                setIsPending(false);
                setError(null);
            })
            .catch(err => {
                setError(err.message);
                setIsPending(false);
            })


        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/pages`)
            .then(res => {
                if(!res.ok) {
                    throw Error('Could not fetch for that resource...');
                }
                return res.json();
            })
            .then((data) => {
                setPages(data.data);
            })
            .catch(err => {
            })


        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);

    }, []);

    return ( 
        <div className="home">
            <Helmet>
                <title>FAMCO Since 1984 | Bone Meal Neem Cake Mysore Meal Organic Manure</title>
		<meta name="viewport" content= "width=device-width, initial-scale=1.0"></meta>
                <meta name="Description" content="FAMCO (House of Quality Products), is a mysore based organic fertiliser manufacturing company was launched in 1984. We manufacture and supplies Bone Meal, Neem Cake, Mysore Meal and Other Organic Fertilisers. We are an ISO 9001:2015 and ISO 14001:2015 certified company. We are one of kerala's preferred choices for Bone Meal"></meta>
		<meta name="Keywords" content="FAMCO, FAMCO Mysore, Bone Meal, Neem Cake, Mysore Meal, Organic Fertilizer, Kerala Bone Meal, Pure Bone Meal, Neem Cake For Organic Gardening, Organic Fertilizer, Fertilizer"></meta>
		<meta property="og:title" content="FAMCO Since 1984 | Bone Meal Neem Cake Mysore Meal Organic Manure"></meta>
		<meta property="og:site_name" content="FAMCO Since 1984"></meta>
		<meta property="og:url" content="https://famco.in/"></meta>
		<meta property="og:description" content="FAMCO (House of Quality Products), is a mysore based organic fertiliser manufacturing company was launched in 1984. We  manufactures and supply Bone Meal, Neem Cake, Mysore Meal and Other Organic Fertilisers. We are  an ISO 9001:2015 and ISO 14001:2015 certified company. We are one of kerala's preferred choices for Bone Meal"></meta>
		<meta property="og:type" content="website"></meta>
		<meta property="og:image" content=""></meta>
		<link rel="canonical" href="https://www.famco.in/" ></link>
            </Helmet>

            { error && <div className="loading text-center">{ error }</div>}

            { isPending && <div className="loading text-center">Loading...</div>}

            { home.length > 0 &&
                <section>
                { home.map(ho => (
                    <section key={ ho.id }>
                        
                        <section className="banner">
                            <div className="container-fluid">
                                <div className="row position-relative justify-content-center">
                                    <div className="col-12 p-0 d-flex justify-content-center position-relative">
                                        <img src={require('../assets/images/home/banner.png')} alt="Banner" className="background"/>
                                        {/* <h2 className="title">NATURAL ORGANIC</h2> */}
                                    </div>
                                    {/* <div className="col-8 box">
                                        <div className="row position-relative align-items-center">
                                            <div className="col-0 col-md-3">
                                                <img src={require('../assets/images/home/bg_1.png')} alt="Secondary" className="left"/>
                                            </div>

                                            <div className="col-12 col-md-6 text-center">
                                                <img src={require('../assets/images/home/banner_image.png')} alt="Main" className="middle"/>
                                            </div>

                                            <div className="col-0 col-md-3">
                                                <img src={require('../assets/images/home/bg_2.png')} alt="Secondary" className="right"/>
                                            </div>
                                            
                                        </div>
                                    </div> */}

                                    <div className="col-12 col-md-10 box">
                                        <div className="row position-relative align-items-center">
                                            <div className="col-12">
                                                <img src={require('../assets/images/home/home_banner_image.png')} alt="Secondary"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="welcome">
                            <div className="min-wrapper">
                                <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12 text-center">
                                            <h3 className="title">{ ho.welcome_title } <span className="famco">{ ho.welcome_title_span }</span></h3>
                                            <p className="description">{ ho.welcome_sub_description }</p>

                                            <p className="paragraph">{ ho.welcome_description }</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="vision">
                            <div className="wrapper">
                                <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-7 left">
                                            <h3 className="title">{ ho.vision_title }</h3>
                                            <p className="description">{ ho.vision_description }</p>

                                            <img src={require('../assets/images/home/leaf_right.png')} alt="Leaf" className="leaf"/>
                                        </div>
                                        <div className="col-12 col-md-5 right">
                                            <img src={require('../assets/images/home/back_lines.png')} alt="Back Lines" className="back-lines"/>
                                            <img src={ ho.vision_image } alt="Vision" className="vision-image"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="mission">
                            <div className="min-wrapper">
                                <div className="container-fluid">
                                    <div className="row justify-content-center">
                                        <div className="col-12">
                                            <h3 className="title">Our Mission</h3>
                                        </div>

                                        { ho.missions.map(mission => (

                                            <div className="single" key={ mission.id }>
                                                <p className="sub-title">{ mission.mission }</p>

                                                {/* <Link className="read-more" to="#">Read More</Link> */}
                                            </div>

                                        ))}

                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="home-about-us">

                            <div className="container-fluid top">
                                <div className="row">
                                    <div className="gray-box"></div>
                                </div>
                            </div>

                            <div className="wrapper">
                                <div className="container-fluid">
                                    <div className="row justify-content-center">

                                        <div className="col-12 col-md-6 left">

                                            <h3 className="title mobile">{ ho.about_title }</h3>

                                            <img src={require('../assets/images/home/w_icon.png')} alt="W Icon" className="w-icon-top"/>
                                            <img src={require('../assets/images/home/w_icon.png')} alt="W Icon" className="w-icon-bottom"/>

                                            <img src={ ho.about_image } alt="About" className="about-image"/>
                                        </div>
                                        <div className="col-12 col-md-6 right">
                                            <h3 className="title desktop">{ ho.about_title }</h3>
                                            <p className="description">{ ho.about_description }</p>

                                            <Link className="click-here" to="/about-us">Click Here</Link>

                                            <img src={require('../assets/images/home/leaf_left.png')} alt="Leaf" className="leaf"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>

                        { pages.map((page, index) => (
                            index === 2 && page.name === 'Products' && page.status === 1 ? (
                                <section className="home-products" key={ index }>
                                    <div className="min-wrapper">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-12 p-0">
                                                    <h3 className="title">Our Products</h3>
                                                </div>
                                            </div>

                                            { ho.products.map((product,index) => (

                                                index % 2 === 0 ? (
                                                    <Link to={ "/products" } className="product-link" key={ index }>
                                                        <div className="row home-single-product first" key={ product.id }>
                                                            <div className="col-12 col-md-5 mb-3 mb-md-0 left">
                                                                <div className="image-div">
                                                                    <img src={ product.image } alt="Product" className="image"/>
                                                                    <div className="name">
                                                                        { product.name }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-7 right">
                                                                <div className="content">
                                                                    <h6 className="content-title">Usage</h6>
                                                                    <div className="attributes">

                                                                        { product.sub_images.map((sub_image,index) => (
                                                                            <p key={ index }>
                                                                                <span>{ sub_image.name }</span> - { sub_image.description }
                                                                            </p>
                                                                        ))}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                
                                                ) :

                                                    (!isDesktop ? (
                                                        <Link to={ "/products" } className="product-link" key={ index }>
                                                            <div className="row home-single-product first mobile" key={ product.id }>
                                                                <div className="col-12 col-md-5 mb-3 mb-md-0 left">
                                                                    <div className="image-div">
                                                                        <img src={ product.image } alt="Product" className="image"/>
                                                                        <div className="name">
                                                                            { product.name }
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-7 right">
                                                                    <div className="content">
                                                                        <h6 className="content-title">Usage</h6>
                                                                        <div className="attributes">

                                                                            { product.sub_images.map((sub_image,index) => (
                                                                                <p key={ index }>
                                                                                    <span>{ sub_image.name }</span> - { sub_image.description }
                                                                                </p>
                                                                            ))}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        ) : (
                                                            <Link to={ "/products" } className="product-link" key={ index }>
                                                                <div className="row home-single-product second desktop" key={ product.id }>
                                                    
                                                                    <div className="col-7 left">
                                                                        <div className="content">
                                                                            <h6 className="content-title">Usage</h6>
                                                                            <div className="attributes">

                                                                                { product.sub_images.map((sub_image,index) => (
                                                                                    <p key={ index }>
                                                                                        <span>{ sub_image.name }</span> - { sub_image.description }
                                                                                    </p>
                                                                                ))}

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-5 right">
                                                                        <div className="image-div">
                                                                            <img src={ product.image } alt="Product" className="image"/>
                                                                            <div className="name">
                                                                                { product.name }
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </Link>
                                                            )
                                                    )
                                                )

                                            )}

                                            <Link to="/products" className="more-products">More Products</Link>

                                        </div>
                                    </div>
                                </section>
                            ) : null
                        ))}

                        { pages.map((page, index) => (
                            index === 3 && page.name === 'Award & Certifications' && page.status === 1 ? (
                                <section className="home-awards" key={ index }>
                                    <div className="wrapper">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-12 p-0">
                                                    <h3 className="title">Awards and Certifications</h3>

                                                    <Swiper
                                                        modules={[Navigation, Pagination]}
                                                        loop={true}
                                                        spaceBetween={30}
                                                        slidesPerView={2}
                                                        navigation
                                                        breakpoints={{
                                                            0: {
                                                                slidesPerView: 1,
                                                                spaceBetween: 0
                                                            },
                                                            429: {
                                                                slidesPerView: 2,
                                                            },
                                                        }}
                                                    >

                                                        { ho.awards.map(award => (

                                                            <SwiperSlide className="single-award" key={ award.id }>

                                                                <div className="single">
                                                                    <img src={ award.image } alt="Award" className="award"/>
                                                                    <p className="description">{ award.description }</p>
                                                                </div>
                                                                
                                                            </SwiperSlide>

                                                        ))}

                                                    </Swiper>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            ) : null
                        ))}

                        { pages.map((page, index) => (
                            index === 4 && page.name === 'Testimonials' && page.status === 1 ? (
                                <section className="home-testimonials" key={ index }>
                                    <div className="lowest-wrapper">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-12 p-0">
                                                    <h3 className="title">Testimonials</h3>

                                                    <Swiper
                                                        modules={[Navigation, Pagination]}
                                                        loop={true}
                                                        spaceBetween={50}
                                                        slidesPerView={1}
                                                        pagination={{ clickable: true }}
                                                    >

                                                        { ho.testimonials.map(testimonial => (

                                                            <SwiperSlide className="single-testimonial" key={ testimonial.id }>
                                                                <div className="single">
                                                                    <div className="image">
                                                                        <img src={require('../assets/images/home/testimonial_back.png')} alt="Testimonial Circle" className="testimonial-back"/>
                                                                        <img src={ testimonial.image } alt="Testimonial" className="testimonial-image"/>
                                                                    </div>

                                                                    <div className="quote">
                                                                        <img src={require('../assets/images/home/quote_top.png')} alt="Quote" className="quote-icon top"/>
                                                                        <p className="description">{ testimonial.comment }</p>

                                                                        <p className="name">{ testimonial.name }</p>

                                                                        <img src={require('../assets/images/home/quote_bottom.png')} alt="Quote" className="quote-icon bottom"/>
                                                                    </div>
                                                                    
                                                                </div>
                                                                
                                                                

                                                            </SwiperSlide>

                                                        ))}

                                                    </Swiper>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            ) : null
                        ))}

                        { pages.map((page, index) => (
                            index === 5 && page.name === 'Blogs' && page.status === 1 ? (
                                <section className="articles" key={ index }>
                                    <div className="wrapper">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-12 p-0">
                                                    <Swiper
                                                        modules={[Navigation, Pagination]}
                                                        loop={true}
                                                        spaceBetween={50}
                                                        slidesPerView={1}
                                                        navigation
                                                    >

                                                        { ho.blogs.map(blog => (

                                                            <SwiperSlide className="single-article" key={ blog.id }>
                                                                <Link to={ `/blogs/${blog.id}` }>
                                                                    <div className="row justify-content-between align-items-center position-relative">

                                                                        <div className="left">
                                                                            <img src={ blog.image } alt="Blog" className="article-image"/>
                                                                        </div>
                                                                        <div className="right">
                                                                            <h5 className="title">{ blog.title }</h5>
                                                                            <div className="description" dangerouslySetInnerHTML={{ __html: blog.description }} />
                                                                        </div>

                                                                        {/* <img src={require('../assets/images/home/article_line.png')} alt="Article Line" className="article-line"/> */}
                                                                    </div>
                                                                </Link>
                                                            </SwiperSlide>

                                                        ))}

                                                    </Swiper>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            ) : null
                        ))}

                    </section>

                )) }
                </section>
            }

        </div>
     );
}
 
export default Home;
