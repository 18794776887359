import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Blogs = () => {

    const [blogs, setBlogs] = useState([]);
    const [search, setSearch] = useState('');
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {

        // document.title = 'Famco | Blogs';

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/blogs`)
            .then(res => {
                if(!res.ok) {
                    throw Error('Could not fetch for that resource...');
                }
                return res.json();
            })
            .then((data) => {
                setBlogs(data.data);
                setIsPending(false);
                setError(null);
            })
            .catch(err => {
                setError(err.message);
                setIsPending(false);
            })

    }, []);


    return (

        <div className="blogs">

            <Helmet>
                <title>Blogs</title>
                <meta name="Blogs" content="Blogs"></meta>
            </Helmet>

            <section className="search">
                <div className="low-wrapper">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6 search-bar">
                                <input
                                    type="text"
                                    className="form-control text-center"
                                    id="keyword"
                                    name="keyword"
                                    placeholder="Search articles"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <span type="button" className="search-icon"><i className="bi bi-search"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            { error && <div className="loading text-center">{ error }</div> }

            { isPending && <div className="loading text-center">Loading...</div> }
            
            { blogs.length > 0 && 
                <section className="articles">
                    <div className="min-wrapper">
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    
                                    <h1 className="title">Read our <span>ARTICLES</span> regularly</h1>

                                    <ul className="nav nav-pills" id="pills-tab">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all" aria-selected="true">All</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-popular-tab" data-bs-toggle="pill" data-bs-target="#pills-popular" type="button" role="tab" aria-controls="pills-popular" aria-selected="false">Popular</button>
                                        </li>
                                    </ul>

                                    <div className="tab-content" id="pills-tabContent">

                                        <div className="tab-pane fade show active" id="pills-all">
                                            <div className="container-fluid">
                                                
                                                { blogs.filter(val => {
                                                    if (search === "") {
                                                        return val;
                                                    }
                                                    else if (val.title.toLowerCase().includes(search.toLowerCase())) {
                                                        return val;
                                                    }
                                                }).map((blog, index) => (

                                                    index === 0 ? (

                                                        <Link to={ `/blogs/${blog.id}` } key={ index }>
                                                            <div className="row top">
                                                                
                                                                <div className="left">
                                                                    <img src={ blog.image } className="w-100 blog-main-image" alt="Blog" />
                                                                </div>
                                                                <div className="right">
                                                                    <h5 className="blog-main-title">{ blog.title }</h5>
                                                                    <div className="blog-main-description" dangerouslySetInnerHTML={{ __html: blog.description }} />

                                                                    <div className="author">
                                                                        <div className="image">
                                                                            <img src={ blog.author_image } alt="Author" className="author-image" />
                                                                        </div>
                                                                        <div className="details">
                                                                            <h5 className="name">{ blog.author_name }</h5>
                                                                            <p className="date">{ blog.date }</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>

                                                    ) : null)

                                                )}

                                                <div className="row bottom">

                                                    { blogs.filter(val => {
                                                        if (search === "") {
                                                            return val;
                                                        }
                                                        else if (val.title.toLowerCase().includes(search.toLowerCase())) {
                                                            return val;
                                                        }
                                                    }).map((blog, index) => (

                                                        index !== 0 ? (

                                                            <div className="single-article" key={ index }>
                                                                <Link to={ `/blogs/${blog.id}` }>
                                                                    <img src={ blog.image } className="w-100 blog-sub-image" alt="Blog" />

                                                                    <h5 className="blog-sub-title">{ blog.title }</h5>
                                                                    <div className="blog-sub-description" dangerouslySetInnerHTML={{ __html: blog.description }} />

                                                                    <div className="author">
                                                                        <div className="image">
                                                                            <img src={ blog.author_image } alt="Author" className="author-image" />
                                                                        </div>
                                                                        <div className="details">
                                                                            <h5 className="name">{ blog.author_name }</h5>
                                                                            <p className="date">{ blog.date }</p>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>

                                                        ) : null)
                                                    ) }

                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="pills-popular">
                                            <div className="container-fluid">
                                                
                                                { blogs.filter((val) => {
                                                    if (search === "") {
                                                        if (val.popular === 'yes') {
                                                            return val;
                                                        }
                                                    }
                                                    else if (val.title.toLowerCase().includes(search.toLowerCase())) {
                                                        if (val.popular === 'yes') {
                                                            return val;
                                                        }
                                                    }
                                                }).map((blog, index) => (

                                                    index === 0 ? (

                                                        <Link to={ `/blogs/${blog.id}` } key={ index }>
                                                            <div className="row top">
                                                                
                                                                <div className="left">
                                                                    <img src={ blog.image } className="w-100 blog-main-image" alt="Blog Main" />
                                                                </div>
                                                                <div className="right">
                                                                    <h5 className="blog-main-title">{ blog.title }</h5>
                                                                    <div className="blog-main-description" dangerouslySetInnerHTML={{ __html: blog.description }} />

                                                                    <div className="author">
                                                                        <div className="image">
                                                                            <img src={ blog.author_image } alt="Author" className="author-image" />
                                                                        </div>
                                                                        <div className="details">
                                                                            <h5 className="name">{ blog.author_name }</h5>
                                                                            <p className="date">{ blog.date }</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>

                                                    ) : null)

                                                )}

                                                <div className="row bottom">

                                                    { blogs.filter(val => {
                                                        if (search === "") {
                                                            if (val.popular === 'yes') {
                                                                return val;
                                                            }
                                                        }
                                                        else if (val.title.toLowerCase().includes(search.toLowerCase())) {
                                                            if (val.popular === 'yes') {
                                                                return val;
                                                            }
                                                        }
                                                    }).map((blog, index) => (

                                                        index !== 0 ? (

                                                            <div className="single-article" key={ index }>
                                                                <Link to={ `/blogs/${blog.id}` }>
                                                                    <img src={ blog.image } className="w-100 blog-sub-image" alt="Article" />

                                                                    <h5 className="blog-sub-title">{ blog.title }</h5>
                                                                    <div className="blog-sub-description" dangerouslySetInnerHTML={{ __html: blog.description }} />

                                                                    <div className="author">
                                                                        <div className="image">
                                                                            <img src={ blog.author_image } alt="Author" className="author-image" />
                                                                        </div>
                                                                        <div className="details">
                                                                            <h5 className="name">{ blog.author_name }</h5>
                                                                            <p className="date">{ blog.date }</p>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>

                                                        ) : null)

                                                    )}

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }

        </div>
        
    );
}

export default Blogs;