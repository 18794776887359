import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const Awards = () => {

    const [isDesktop, setDesktop] = useState(window.innerWidth > 650);

    const updateMedia = () => {
        setDesktop(window.innerWidth > 650);
    };

    const [awards, setAwards] = useState([]);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        // document.title = 'Famco | Awards & Certifications';

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/awards`)
            .then(res => {
                if(!res.ok) {
                    throw Error('Could not fetch for that resource...');
                }
                return res.json();
            })
            .then((data) => {
                setAwards(data.data);
                setIsPending(false);
                setError(null);
            })
            .catch(err => {
                setError(err.message);
                setIsPending(false);
            })

        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
        
    }, []);


    return (

        <div className="awards">
            <Helmet>
                <title>FAMCO Since 1984 | Awards</title>
                <meta name="Description" content="FAMCO is an ISO 9001:2015, ISO 14001:2015 certified Bone Meal, Neem Cake and Mysore Meal Supplier. The Managing Director was recently awarded Entrepreneur Icon Award By Cartlton Media"></meta>
		<meta name="Keywords" content="FAMCO, FAMCO Mysore, Bone Meal, Neem Cake, Mysore Meal, Organic Fertilizer, Kerala Bone Meal, Pure Bone Meal, Neem Cake For Organic Gardening, Organic Fertilizer, Fertilizer"></meta>
            </Helmet>

            <div className="min-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="title">Our Glorious <span>ACHIEVEMENT</span>s</h1>
                        </div>
                    </div>

                    { error && <div className="loading text-center">{ error }</div>}

                    { isPending && <div className="loading text-center">Loading...</div>}

                    { awards.length > 0 &&
                        <div className="all-awards">

                            { awards.map((award, index) => (

                                index % 2 === 0 ? (
                                    <div className="single-award row" key={ award.id }>
                                        <div className="col-12 col-md-5 mb-3 mb-md-0">
                                            <div className="image-div">
                                                <img src={ award.image } alt="Award" className="award-image" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-7">
                                            <p className="award-title">{ award.name }</p>
                                            <p className="award-description">{ award.description }</p>
                                        </div>
                                        
                                    </div>
                                ) : (

                                    (!isDesktop ? (
                                        <div className="single-award row" key={ award.id }>
                                            <div className="col-12 col-md-5 mb-3 mb-md-0">
                                                <div className="image-div">
                                                    <img src={ award.image } alt="Award" className="award-image" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-7">
                                                <p className="award-title">{ award.name }</p>
                                                <p className="award-description">{ award.description }</p>
                                            </div>
                                            
                                        </div>
                                    ) : (
                                        <div className="single-award row" key={ award.id }>

                                            <div className="col-12 col-md-7">
                                                <p className="award-title">{ award.name }</p>
                                                <p className="award-description">{ award.description }</p>
                                            </div>

                                            <div className="col-12 col-md-5 mb-3 mb-md-0">
                                                <div className="image-div">
                                                    <img src={ award.image } alt="Award" className="award-image" />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                ) 
                                )
                            ))}

                        </div>
                    }
                </div>
            </div>
        </div>

    );
}

export default Awards;
