import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const ContactUs = () => {

    // document.title = 'Famco | Contact Us';

    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const [isPending, setIsPending] = useState(false);

    const [branches, setBranches] = useState([]);
    const [branchesPending, setBranchesPending] = useState(true);
    const [branch_error, setBranchError] = useState(null);

    const [contactDetails, setContactDetails] = useState([]);
    // const [contactDetailsIsPending, setContactDetailsIsPending] = useState(true);
    // const [error, setError] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();

        const success_modal = document.getElementById("success");
        const modal_back = document.getElementById("modal-backdrop");
        const close = document.getElementById('btn-close');

        const contact = { first_name, last_name, email, phone, message };

        setIsPending(true);

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/contact`, {
            method: 'POST',
            headers: { "Content-Type" : "application/json" },
            body: JSON.stringify(contact)
        })
        .then(() => {
            success_modal.style.display = "block";
            success_modal.classList.add('show');
            modal_back.classList.add('modal-backdrop', 'fade', 'show');

            setIsPending(false);

            close.addEventListener('click', (e) => {
                success_modal.style.display = "none";
                success_modal.classList.remove('show');
                modal_back.classList.remove('modal-backdrop', 'fade', 'show');
                
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhone('');
                setMessage('');
            })
        });

    }

    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/branches`)
            .then(res => {
                if(!res.ok) {
                    throw Error('Could not fetch for that resource...');
                }
                return res.json();
            })
            .then((data) => {
                setBranches(data.data);
                setBranchesPending(false);
                setBranchError(null);
            })
            .catch(err => {
                setBranchError(err.message);
                setBranchesPending(false);
            })


        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/contact-details`)
            .then(res => {
                if(!res.ok) {
                    throw Error('Could not fetch for that resource...');
                }
                return res.json();
            })
            .then((data) => {
                setContactDetails(data.data);
                // setContactDetailsIsPending(false);
                // setError(null);
            })
            .catch(err => {
                // setError(err.message);
                // setIsPending(false);
            })
        
    }, []);

    return (
        <div className="contact-us">

            <Helmet>
                <title>FAMCO Since 1984 | Contact Us</title>
                <meta name="Description" content="For Best Bone Meal, Neem Cake, Mysore Meal and Organic Fertilisers at best prices please contact us at 9448739609 and 9035037605"></meta>
            	<meta name="Keywords" content="FAMCO, FAMCO Mysore, Bone Meal, Neem Cake, Mysore Meal, Organic Fertilizer, Kerala Bone Meal, Pure Bone Meal, Neem Cake For Organic Gardening, Organic Fertilizer, Fertilizer"></meta>
	    </Helmet>


            <section className="connected">
                <div className="low-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 p-0">
                                <h1 className="title">Keep <span>CONNECTED</span> with us</h1>

                                <form className="form" onSubmit={handleSubmit} id="contact-form">
                                    <h3 className="form-title">Do you have any question? Just send a text</h3>

                                    <div className="row fields"> 
                                        <div className="col-12 col-md-6 form-input">
                                            <label className="form-label">First Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                required
                                                value={first_name}
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                        </div>

                                        <div className="col-12 col-md-6 form-input">
                                            <label className="form-label">Last Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                required
                                                value={last_name}
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </div>

                                        <div className="col-12 form-input">
                                            <label className="form-label">Email Address</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                required
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>

                                        <div className="col-12 form-input">
                                            <label className="form-label">Phone Number</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                required
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                            />
                                        </div>

                                        <div className="col-12 form-input">
                                            <label className="form-label">Write your message here...</label>
                                            <textarea
                                                className="form-control"
                                                required
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                            />
                                        </div>

                                        <div className="col-12 form-input mt-2 mt-md-4">
                                            { !isPending && <button type="submit" className="btn send-btn"><i className="bi bi-send"></i>Send</button> }

                                            { isPending && <button type="submit" className="btn sending-btn" disabled><i className="bi bi-send"></i>Sending Message...</button> }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            { branch_error && <div className="loading text-center">{ branch_error }</div>}

            { branchesPending && <div className="loading text-center">Loading...</div>}

            { branches.length > 0 &&
                <section className="contact">
                    <div className="low-wrapper">
                        <div className="container-fluid">

                            { branches.map((branch, index) => (

                                index === 0 ? (
                                    <div className="row information" key={ index }>
                                        <div className="col-12">
                                            <h1 className="title">{ branch.title }</h1>
                                        </div>

                                        <div className="col-12 col-md-7 mb-4 mb-md-0">
                                            <iframe src={ branch.google_map_link } loading="lazy" title="Google Map"></iframe>

                                            <p className="contact-icon">
                                                <a href={ `tel:${ branch.phone }` }><img src={require('../assets/images/contact_us/phone_icon.png')} alt="Icon" className="icon" />{ branch.phone }</a>
                                                </p>

                                            <p className="contact-icon">
                                                <a href={ `mailto:${ branch.email }` }><img src={require('../assets/images/contact_us/email_icon.png')} alt="Icon" className="icon" />{ branch.email }</a>
                                            </p>
                                        </div>

                                        <div className="col-12 col-md-5">
                                            <h5 className="location-title">Location</h5>
                                            <div className="address">
                                                <p>Address : { branch.address }</p>
                                                <p>City : { branch.city }</p>
                                                <p>State/province/area : { branch.state }</p>
                                                <p>Zip Code : { branch.zip_code }</p>
                                                <p>Country : { branch.country }</p>
                                            </div>

                                            <div className="social">
                                                <a href={ contactDetails.fb_link } target="_blank" rel="noreferrer">
                                                    <img src={require('../assets/images/common/fb_icon.png')} alt="FB Icon" className="icon" />
                                                </a>
                                                <a href={ contactDetails.linked_in_link } target="_blank" rel="noreferrer">
                                                    <img src={require('../assets/images/common/linked_icon.png')} alt="Linked In Icon" className="icon" />
                                                </a>
                                                <a href={ contactDetails.twitter_link } target="_blank" rel="noreferrer">
                                                    <img src={require('../assets/images/common/twitter_icon.png')} alt="Twitter Icon" className="icon" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row information" key={ index }>
                                        <div className="col-12">
                                            <h1 className="title">{ branch.title }</h1>
                                        </div>

                                        <div className="col-12 col-md-7 mb-4 mb-md-0">
                                            <iframe src={ branch.google_map_link } loading="lazy" title="Google Map"></iframe>

                                            <p className="contact-icon">
                                                <a href={ `tel:${ branch.phone }` }><img src={require('../assets/images/contact_us/phone_icon.png')} alt="Icon" className="icon" />{ branch.phone }</a>
                                                </p>

                                            <p className="contact-icon">
                                                <a href={ `mailto:${ branch.email }` }><img src={require('../assets/images/contact_us/email_icon.png')} alt="Icon" className="icon" />{ branch.email }</a>
                                            </p>
                                        </div>

                                        <div className="col-12 col-md-5">
                                            <h5 className="location-title">Location</h5>
                                            <div className="address">
                                                <p>Address : { branch.address }</p>
                                                <p>City : { branch.city }</p>
                                                <p>State/province/area : { branch.state }</p>
                                                <p>Zip Code : { branch.zip_code }</p>
                                                <p>Country : { branch.country }</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                                
                            ))}

                        </div>
                    </div>
                </section>
            }

            <div className="modal fade success-modal" id="success">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <p className="message-sent">Message sent successfully!</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" id="btn-close">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="modal-backdrop"></div>

        </div>
    );
}

export default ContactUs;
