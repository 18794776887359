import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Footer from './includes/Footer';
import Navbar from './includes/Navbar';
import ScrollToTop from './includes/ScrollToTop';
import AboutUs from './views/AboutUs';
import Awards from './views/Awards';
import Blogs from './views/Blogs';
import ContactUs from './views/ContactUs';
import Home from './views/Home';
import Management from './views/Management';
import Products from './views/Products';
import SingleBlog from './views/SingleBlog';
import Testimonials from './views/Testimonials';



function App() {

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Navbar />

        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about-us">
            <AboutUs />
          </Route>
          <Route path="/management">
            <Management />
          </Route>
          <Route path="/products">
            <Products />
          </Route>
          <Route path="/awards-certifications">
            <Awards />
          </Route>
          <Route path="/testimonials">
            <Testimonials />
          </Route>
          <Route exact path="/blogs">
            <Blogs />
          </Route>
          <Route path="/blogs/:id">
            <SingleBlog />
          </Route>
          <Route path="/contact-us">
            <ContactUs />
          </Route>
        </Switch>

        <Footer />
        
      </div>
    </Router>
  );
}

export default App;